<template>
	<div style="justify-content: center; align-items: center; width: 100%; height: 100%;">
		<IframeAux id="iframe-player" :source="source" :isMobile="isMobile"></IframeAux>
	</div>
</template>
<script>
import notify from "@/services/libs/notificacao";
import IframeAux from './VideoPlayerIframeAux.vue'
export default {
    props:['source', 'porcentagem', 'height', 'isMobile'],
	data() {
		return {
			options: {
				muted: false,
                autoplay: true,
			},
			playerReady: false,
			t:''
		}
	},
	components: { IframeAux },
	methods: {
		todo(type){
			//console.log(type);
			if(type === 'play'){
				this.$refs.player.play();
			}else if(type === 'concluiu'){
				this.$root.$emit('liberaBtn');
			}else if(type === 'calcula'){
				this.$root.$emit('calculaBtn');
			}else if(type === 'next'){
				this.$root.$emit('liberaBtnNext');
			}else{
				this.$refs.player.pause();
				//console.log('pauseee');
			}
		},
		async calculaPorcentagem(type){
			if(type === 'concluir'){
				this.todo('concluiu');
			}else if(type === 'calcula'){
				this.todo('calcula');
			}else{
				this.todo('next');
			}
		},
	},
	mounted(){
		this.$root.$on('clicouConcluir', data => {
			//console.log('xxxxxxxxx', data);
			if(data === 'iframe'){
				this.calculaPorcentagem('concluir');
			}
		});
		this.$root.$on('clicouNext', data => {
			if(data === 'iframe'){
				this.calculaPorcentagem('next');
			}
		});
		this.$root.$on('calcula', data => {
			if(data === 'iframe'){
				this.calculaPorcentagem('calcula');
			}
		});

	},
  beforeDestroy() {
      //console.log('beforeDestroy');
  },
}
</script>

<style>
.toZindex .playerVimeo {
    z-index: 99999999999999999;
}

#iframe-player iframe{
	width: -webkit-fill-available !important;
}

#iframe-player div {
  display: flex;
	max-width: 980px;
	height: 400px;
  justify-content: center !important;
  background: #000;
  border-radius: 6px;
}
</style>