<template>
    <iframe ref="iframe" :src="source" 
    style="border: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
    allowfullscreen="true" 
    :style="`${calcHeight}`"
    >
</iframe>
</template>
<script>
export default {
    props:['source', 'isMobile'],
      mounted(){
        this.sourceTreated();
        window.addEventListener("resize", () => {
        this.sourceTreated();
      });
    },
    computed: {
      calcHeight() {
        if (this.isMobile) {
          return 'min-width: 100%; position: relative; max-height: 250px; min-height: 250px !important;'
        } else {
          return 'min-width: 100%; position: relative; max-height: calc(100vw / 2.66); min-height: calc(100vw / 2.66) !important;'
        }
      }
    },
    methods:{
      sourceTreated() {
        let iframe = this.$refs.iframe;
        if(iframe && iframe.style){
          if(this.source.includes('scaleup') && window.innerWidth < 1500){
            iframe.style.minWidth = '100%';
            iframe.style.minHeight = `${iframe.clientWidth * 0.5625}px`;
            iframe.style.maxHeight = `${iframe.clientWidth * 0.5625}px`;
            return;
          }else if(this.source.includes('scaleup')  && window.innerWidth > 1500){
            iframe.style.minWidth = '889px';
            iframe.style.minHeight = '545px';
            iframe.style.maxHeight = '545px';
            return;
          }else if(this.source.includes('panda')){
            iframe.style.minHeight = `${iframe.clientWidth * 0.5625}px`;
            iframe.style.maxHeight = `${iframe.clientWidth * 0.5625}px`;
            return;
          }
      }
    }
    }
}
</script>

<style>
iframe {
  max-width: 980px;
	height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>